import axios from "axios";

import {
  ADD_TO_CART,
  BULK_ADD_TO_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  DELETE_ALL_FROM_CART,
  DELETE_FROM_CART,
  SET_CART_FROM_API,
} from "../constant_types";

import { ADD_TO_CART_URL } from "../../constants/config/config.local";

const handleCartItem = (cartItems, newItem) => {
  const itemIndex = cartItems?.findIndex((item) => item.id == newItem.id);

  if (itemIndex === -1) {
    return [
      { product_id: newItem.id, qty: newItem.qty },
      ...cartItems?.map((item) => {
        return { product_id: item.id, qty: item.quantity };
      }),
    ];
  } else {
    return cartItems.map((item) => {
      if (item.id === newItem.id) {
        // cart qty is quantity and new qty is qty
        return { product_id: item.id, qty: item.quantity + newItem.qty };
      } else {
        return { product_id: item.id, qty: item.quantity };
      }
    });
  }
};

const handleBulkCartItems = (items) => {
  return items.map((item) => {
    return {
      product_id: item.id * 1,
      qty: item?.enterQuantity * 1 || item?.quantity * 1,
    };
  });
};

const handleDecrementCartItem = (cartItems, prevItem) => {
  // const abc =
  // if (prevItem.quantity <= 1) {
  //   let temp = cartItems.filter((item) => item.id !== prevItem.id);
  //   const cart = temp?.map((tempCart) => {
  //     return { product_id: tempCart.id, qty: tempCart.quantity };
  //   });

  //   return cart;
  // }
  if (prevItem.quantity > 0) {
    return cartItems
      .map((item) => {
        if (item.id === prevItem.id) {
          return { product_id: item.id, qty: item.quantity - 1 };
        } else {
          return { product_id: item.id, qty: item.quantity };
        }
      })
      ?.filter((item) => item.quantity > 0);
  }
};

const handleIncreamentCartItem = (cartItems, prevItem) => {
  // const abc =
  return cartItems.map((item) => {
    if (item.id === prevItem.id) {
      return { product_id: item.id, qty: item.quantity + 1 };
    } else {
      return { product_id: item.id, qty: item.quantity };
    }
  });
};

//add to cart
export const addToCart = (
  item,
  addToast,
  cartItems,
  quantityCount
) => {
  return (dispatch, getState) => {
    if (["0", 0].includes(quantityCount)) {
      if (addToast) {
        addToast(`You can't add product with 0 quantity`, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      return;
    }

    const store = getState();
    const cartItem = store.cartData.find((_item) => _item.id === item.id);
    if (
      item?.ps_allowed_qty === 0 ||
      (item?.ps_allowed_qty &&
        Number(quantityCount) + (cartItem?.quantity || 0) > item.ps_allowed_qty)
    ) {
      const errorMessage = `Sorry,the Max allowed quantity per sale is ‘MAx Qty’.`;
      addToast(errorMessage, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    const cart_items = handleCartItem(cartItems, {
      ...item,
      qty: quantityCount * 1 || 1,
    });

    const { name, id, ecom_name } = item;
    axios
      .put(`${ADD_TO_CART_URL}`, {
        cart_items,
      })
      .then((res) => {
        if (addToast) {
          addToast(`${ecom_name || name} added To Cart`, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        dispatch({
          type: ADD_TO_CART,
          payload: {
            ...item,
            quantity: quantityCount * 1,
            selectedProductColor: null,
            selectedProductSize: null,
          },
        });
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) =>
            addToast(`${error}`, {
              appearance: "error",
              autoDismiss: true,
            })
          );
        }
      });
  };
};

export const bulkAddToCart = (
  items,
  addToast,
  additionalOperation,
  allItems,
) => {
  return (dispatch) => {
    const updatedItems = handleBulkCartItems(items);
    console.log("🚀 ~ return ~ updatedItems:", updatedItems)
    axios
      .put(`${ADD_TO_CART_URL}`, {
        cart_items: updatedItems,
      })
      .then((res) => {
        if (addToast) {
          addToast("Added To Cart", {
            appearance: "success",
            autoDismissTimeout: 1000,
            autoDismiss: true,
          });
        }
        dispatch({
          type: BULK_ADD_TO_CART,
          payload: {
            items,
            allItems,
          },
        });
        if (additionalOperation) {
          additionalOperation();
        }
      })
      .catch((err) => {
        console.error("errors",err)
        if (addToast && err?.response?.data?.errors?.map) {
          err.response.data.errors.map((error) =>
            addToast(`${error}`, {
              appearance: "error",
              autoDismiss: true,
            })
          );
        }
      });
  };
};

//decrease from cart
export const decreaseQuantity = (cartItems, item,  addToast) => {
  const { name, ecom_name } = item;
  return (dispatch) => {
    const newCart = handleDecrementCartItem(cartItems, item);
    axios
      .put(`${ADD_TO_CART_URL}`, {
        cart_items: newCart,
      })
      .then((res) => {
        if (res.status == 200 && addToast) {
          addToast(`${ecom_name || name} decremented from cart`, {
            appearance: "warning",
            autoDismiss: true,
          });
          dispatch({ type: DECREASE_QUANTITY, payload: item });
        }
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) =>
            addToast(`${error}`, {
              appearance: "error",
              autoDismiss: true,
            })
          );
        }
      });
  };
};

//decrease from cart
export const increaseQuantity = (cartItems, item, addToast) => {
  const { name, ecom_name } = item;

  return (dispatch) => {
    const newCart = handleIncreamentCartItem(cartItems, item);
    axios
      .put(`${ADD_TO_CART_URL}`, {
        
        cart_items: newCart,
      })
      .then((res) => {
        if (addToast) {
          addToast(`${ecom_name || name} Incremented in cart`, {
            appearance: "warning",
            autoDismiss: true,
          });
        }
        dispatch({ type: INCREASE_QUANTITY, payload: item });
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) =>
            addToast(`${error}`, {
              appearance: "error",
              autoDismiss: true,
            })
          );
        }
      });
  };
};

//delete single product from cart
const deleteSingleItemFromCart = (cartItems, item) => {
  let temp = cartItems.filter((cartItem) => cartItem.id !== item.id);
  return temp.map((tempCart) => {
    return { product_id: tempCart.id, qty: tempCart.quantity };
  });
};

export const deleteFromCart = (cartItems, item,  addToast) => {
  return (dispatch) => {
    let updatedCartItems = deleteSingleItemFromCart(cartItems, item);
    axios
      .put(`${ADD_TO_CART_URL}`, {
        cart_items: updatedCartItems,
      })
      .then((res) => {
        const { ecom_name, name } = item;
        if (addToast) {
          addToast(`${ecom_name || name} removed from Cart`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        dispatch({ type: DELETE_FROM_CART, payload: item });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//delete all from cart
export const deleteAllFromCart = (addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};
// Empty cart via api

export const deleteAllFromCartViaApi = (addToast) => {
  return (dispatch) => {
    axios
      .put(`${ADD_TO_CART_URL}`, {
        cart_items: [],
      })
      .then((res) => {
        if (addToast) {
          addToast("Removed All From Cart", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        dispatch({ type: DELETE_ALL_FROM_CART });
      })
      .catch((err) => {
        console.log("🚀 ~ file: cartActions.js:195 ~ return ~ err:", err);
      });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  try {
    if (item.stock) {
      return item.stock;
    } else {
      return item?.variation
        .filter((single) => single.color === color)[0]
        .size.filter((single) => single.name === size)[0].stock;
    }
  } catch (err) {
    console.log(err);
  }
};

// get Cart items
export const getCustomerCart = () => {
  return (dispatch) => {
    axios
      .get(`${ADD_TO_CART_URL}`)
      .then((res) => {
        dispatch({ type: SET_CART_FROM_API, payload: res.data.entities });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
