// import * as authe from "../app/modules/Auth/_redux/authRedux";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { REFRESH_TOKEN_URL } from "../constants/config/config.local";
import {
  // logoutUser,
  logoutWithWithNormal,
  setAuthToken,
} from "./actions/loginActions";
import { deleteAllFromCart } from "./actions/cartActions";
// import { REFRESH_TOKEN_URL } from "../constants/config";

export default function setupAxios(axios, store) {
  const UNAUTHORIZED = 401;

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // const {
      //   userData: { authToken, refreshToken },
      // } = store.getState();
      let authToken = JSON.parse(localStorage.getItem("user"))?.access_token;
      let refreshToken = JSON.parse(
        localStorage.getItem("user")
      )?.refresh_token;

      const user = authToken ? jwt_decode(authToken) : 0;

      const isLoginRequest =
        config.url.split("dev/")[1] === "core/customers/authenticate";
      // if (user === 0 && !isLoginRequest) {
      //   store.dispatch(logoutUser());
      //   return;
      // }
      if (isLoginRequest) {
        return config;
      }
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if ((authToken || config.headers?.Authorization) && !isExpired) {
        config.headers.Authorization = config.headers?.Authorization || `Bearer ${authToken}`;
        config.headers["Content-Type"] = "application/json";
        return config;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      };

      if (
        isExpired &&
        config.url.split("/")[config.url.split("/").length - 1] !==
          `authenticate`
      ) {
        return new Promise((resolve) => {
          const res = fetch(REFRESH_TOKEN_URL, {
            method: "POST",
            headers: headers,
            body: "",
          });
          res
            .then((r) => r.json())
            .then((result) => {
              config.headers.Authorization = `Bearer ${result.entity.access_token}`;
              store.dispatch(
                setAuthToken({
                  accessToken: result.entity.access_token,
                  refreshToken: result.entity.refresh_token,
                })
              );
            })
            .then(() => {
              resolve(config);
            })
            .catch(() => {
              localStorage.clear();
              store.dispatch(logoutWithWithNormal(true));
            });
        });
      }

      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error.response?.status;
      if (status === UNAUTHORIZED) {
        // localStorage.clear();
        const keysToKeep = ["parentCategory","shop_search_filters", "brandsPreserve"]
        Object.keys(localStorage).forEach(key => {
          if(!keysToKeep.includes(key)) {
            localStorage.removeItem(key)
          }
        } )

        store.dispatch(deleteAllFromCart());
        store.dispatch(logoutWithWithNormal(false));
        // store.dispatch({ type: "[Logout] Action" });
      }
      return Promise.reject(error);
      //       try {
      //         const { status } = error?.response;
      //         if (status === UNAUTHORIZED) {
      //           localStorage.clear();
      //           store.dispatch({ type: "[Logout] Action" });
      //         }
      //         return Promise.reject(error);
      //       } catch (err) {
      //         return Promise.reject(error);
      //       }
    }
  );
}
