import React, { useState } from 'react';
import MobileCategories from './mobileCategories';
import Navigation from './navigation'
import Profile from './profile'


const MobileNavigation = ({ categoryTreeData, setIsActionsVisible, settingData, currentState, profileItems }) => {
  const [activeTab, setActiveTab] = useState('categories');

  console.log('categoryTreeData', categoryTreeData)
  return (
    <div className='mobile-navigation'>
      {/* Tab Navigation */}
      <div className="tabs">
        <button 
          className={activeTab === 'categories' ? 'active' : ''} 
          onClick={() => setActiveTab('categories')}
        >
          Categories
        </button>
        <button 
          className={activeTab === 'menu' ? 'active' : ''} 
          onClick={() => setActiveTab('menu')}
        >
          Menu
        </button>
        <button onClick={() => setIsActionsVisible(false)} className='tw-float-right'>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
        </button>
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {activeTab === 'categories' && (
          <MobileCategories categoryTreeData={categoryTreeData} />
        )}
        {activeTab === 'menu' && (
          <>
            <Profile
              currentState={currentState}
              profileItems={profileItems}
              showText={true}
            />
            <Navigation className={`header-actions`} settingData={settingData} flatten={true}/>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileNavigation;
