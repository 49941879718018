import React from 'react';
import CategoryList from '../header/components/categoryList';
import Brands from './components/brands';

const NavCategoriesTemplate1 = ({ position, activeCategory, brandsData }) => {
  const subCategories = activeCategory?.children ?? [];
  if (!activeCategory || subCategories.length === 0) return null;

  return (
    <div
      className="mega-menu"
      style={position && {top: position.top}}
    >
      <div className="dropdown-content">
        <div className="brand-info">
          <h2>Brands</h2>
          <Brands brandsData={brandsData} showImage={true}/>
        </div>
        
        {subCategories && subCategories.length > 0 && (
          <div className='tw-flex tw-flex-col'>
            <b className='tw-ml-6 tw-capitalize tw-mb-3'>{activeCategory.name.capitalize()}</b>
            <CategoryList activeCategory={activeCategory} categories={subCategories} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavCategoriesTemplate1;
