import React , {useCallback} from "react";
import { ErrorMessage } from "formik";
import { getPath, parseSpecialChr, TextError, TOOLTIP_BG } from "../../pages/constant/utils";
import { DatePicker, Tooltip } from "antd";
import { ReactComponent as Icon } from "../../assets/images/information2.svg";
import {useDropzone} from 'react-dropzone'
import { getPresignedUrl, uploadFileToCloud } from "../../redux/actions/commonActions";

const getFieldCSSClasses = (touched, errors, suffix) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  if (touched && !errors && !suffix) {
    // classes.push("is-valid ");
  }
  if (touched && !errors && suffix) {
    classes.push("input-field-color");
  }

  return classes.join(" ");
};

export function Input({
  field,
  form: { errors, touched, setFieldValue },
  label,
  suffix,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  autoFocus,
  tooltipContent,
  _type,
  setLoader,
  customStyle = false,
  ...props
}) {

  const getFileUploadUrl = (
    name,
    timestamp,
    file,
    otherLiscencekeyName
  ) => {
    let newKey = `media/liscences/${timestamp}/${name}/${parseSpecialChr(file.name)}`
    getPresignedUrl(newKey)
      .then((res) => {
        let { url } = res.data[0];
        let payload = { url, file };
        // url, file
       setLoader && setLoader(true)
        uploadFileToCloud(payload)
          .then(({url}) => {
            setFieldValue(
              otherLiscencekeyName ? otherLiscencekeyName: name,
              url.split("?")[0]
            );
            setLoader && setLoader(false)
          })
          .catch((err) => {
            console.error(err);
            setLoader && setLoader(false)
          });
      })
      .catch((err) => {
        console.log(err);
        setLoader && setLoader(false)
      });
  };


  const onDrop = useCallback(acceptedFiles => {
    getFileUploadUrl(field.name, Date.now(), acceptedFiles[0])
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const propClass = props.className || "";
  if (_type === "date") {
    return <DatePicker style={{ width: "100%" }} {...field} {...props} />;
  }


  const renderLabel = () => {
    if (!label) return null;

    return (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <label>{label}</label>
        {tooltipContent && (
          <Tooltip
            overlayStyle={
              customStyle
                ? { maxWidth: "60%", right: "5%", position: "relative" }
                : {}
            }
            trigger={"click"}
            placement="topLeft"
            color={TOOLTIP_BG.color}
            title={
              <p style={{ color: TOOLTIP_BG.textColor }}>{tooltipContent}</p>
            }
          >
            {/* Replace with an icon or element to trigger tooltip */}
            <span style={{ marginLeft: "8px" }}>
              <Icon />
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <>
      {renderLabel()}
      <div style={{ position: "relative" }}>
      {(type === "file") ?
          <>
          {/* <label className="file-upload">
           <input
          type={type}
          style={{
            height: "45px",
            marginBottom: "5px",
            borderColor: touched[field.name] && errors[field.name] ? "#f64e60" : undefined
          }}
          className={
            type === "file"
              ? "file-input"
              : `customized-input ${propClass} ${getFieldCSSClasses(
                  getPath(field.name, touched),
                  getPath(field.name, errors),
                  suffix
                )} pr-10`
          }
          {...field}
          {...props}
        />
          <span className="file-type-label"> Drag & Drop or <u className="pl-3">Choose File</u></span>
        </label> */}
        <div {...getRootProps()} className="file-upload">
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <span className="file-type-label"> Drag & Drop or <u className="pl-3">Choose File</u></span>
              }
          </div>

        </>:
        <input
          type={type}
          style={{
            height: "45px",
            marginBottom: "5px",
            borderColor: touched[field.name] && errors[field.name] ? "#f64e60" : undefined
          }}
          className={
            type === "file"
              ? "file-input"
              : `customized-input ${propClass} ${getFieldCSSClasses(
                  getPath(field.name, touched),
                  getPath(field.name, errors),
                  suffix
                )} pr-10`
          }
          {...field}
          {...props}
        />}
      </div>
      {withFeedbackLabel && (
        <ErrorMessage name={field.name} component={TextError} />
      )}
    </>
  );
}
