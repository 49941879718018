import React from 'react';

const CategoryItem = ({ skipID, categoryItem, categoryRef, handleActiveCategory, className }) => {
  if (!categoryItem) return null;

  const subCategories = categoryItem.children ?? {};
  const hasSubCategory = subCategories.length > 0;
  
  return (
    <a
      key={categoryItem.id + Math.random()}
      id={!skipID && 'category_item_' + categoryItem.id}
      href={`/shop/?filter_cat=${categoryItem.slug}`}
      className={`category-item tw-items-center ${className ? className : ''} ${hasSubCategory ? 'has-sub-category' : ''}`}
      ref={categoryRef}
      onMouseEnter={() => handleActiveCategory && handleActiveCategory(categoryItem)}
      onClick={(event) => {
        event.preventDefault();
        if(!hasSubCategory) localStorage.setItem("parentCategory", undefined);
        window.location.href = `/shop/?filter_cat=${categoryItem.slug}`;
      }}
    >
      {categoryItem.name.capitalize()}
      {handleActiveCategory && hasSubCategory && (
        <svg
          className="tw-h-5 tw-w-5 tw-flex-none tw-text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </a>
  );
};

export default CategoryItem;
