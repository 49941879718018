import React, { useState, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { useMainUIContext } from "../../../../UIContext";
import { fetchProductRequest } from "../../../../redux/actions/productActions";

const Search = ({ settingData, currency, buttonRight, buttonClassName, wrapperClassName, parentClassName, isIcon = false }) => {
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      searchKey: mainUIContext.searchKey,
      setSearchKey: mainUIContext.setSearchKey,
      enterSearchIcon: mainUIContext.enterSearchIcon, 
      setEnterSearchIcon: mainUIContext.setEnterSearchIcon,
      setSelectedMainCategory: mainUIContext.setSelectedMainCategory,
      setViewAllProducts:mainUIContext.setViewAllProducts,
      setFilter:mainUIContext.setFilter,
      setSearchTag:mainUIContext.setSearchTag,
      setSelectedBrands:mainUIContext.setSelectedBrands,
    };
  }, [mainUIContext]);

  const { searchKey, setSearchKey, setSelectedMainCategory, setEnterSearchIcon, setSearchTag,setFilter,setSelectedBrands } = mainUIProps;


  const handleSearch = useCallback(debounce((e) => {
    const searchKey = e.target.value;
    getProductsList(searchKey);
  }, 1000), []);

  const getProductsList = async (search_key) => {
    try {
      let params = {
        search_key,
        sort_by: "created_at:desc",
        home_search: "menu",
        default_variant: "",
        page_size: 5,
      };

      const response = await fetchProductRequest(params);
      setSearchResults(response.data.entities);
    } catch (err) {}
  };

  const triggerSearch = (searchKey) => {
    let enterSearch = searchKey.replace(/%20/g, ' ');
    localStorage.setItem("shop_search_filters", "");
    history.push(`/shop?search=${enterSearch}`);
    setEnterSearchIcon(true)
    setFilter("")
    setSearchKey(enterSearch)
    setSelectedMainCategory && setSelectedMainCategory("")
    localStorage.setItem("parentCategory", "");
    localStorage.setItem("brandsPreserve", "");
    setSelectedBrands("")
    setIsFocused(false)
  };

  const dropdownMenu = (
    <div className="search-dropdown">
      {isFocused && searchResults.map((product) => (
        <div className="search-dropdown-item" key={product.id} onClick={() => history.push(`/product/${product.slug}`)}>
          <img src={process.env.PUBLIC_URL + product.images[0]} alt={product.ecom_name || product.name} />
          <p>
            {product.ecom_name || product.name}
            {settingData.price_display_for_unsecure_user && (
              <span>
                {currency.currency_symbol}
                {product.selected_unit_price.price}
              </span>
            )}
          </p>
        </div>
      ))}
      <div key="search-all" className="tw-text-center tw-px-4 tw-py-2" onClick={() => triggerSearch(searchKey)}>
        View All Results
      </div>
    </div>
  );

  const searchInput = (
    <div className="action-block tw-w-full">
      <div className={`${wrapperClassName ?? ''} action-search tw-w-full`}>
        <input
          type="text"
          name="search"
          id="search"
          className={`${buttonRight ? '!tw-pl-3 !tw-pr-10' : '!tw-pr-3 !tw-pl-10'} !tw-w-full placeholder:!tw-text-gray-900`}
          placeholder="Search"
          value={searchKey}
          onFocus={() => setIsFocused(true)}
          onChange={(e) => {
            const value = e.target.value;
            setSearchKey(value);
            const newUrl = new URL(window.location.href);
            if (value !== "") {
              newUrl.searchParams.set('search', value);
            } else {
              if(window.location.pathname === '/shop'){
                triggerSearch('')
              }

              newUrl.searchParams.delete('search');
            }
            handleSearch(e);
            window.history.replaceState({}, '', newUrl.toString());
            setIsFocused(value !== "");
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') triggerSearch(searchKey);
          }}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
              if (isIcon) setIsSearchVisible(false);
            }, 300);
          }}
        />
        {searchResults.length > 0 && isFocused && dropdownMenu}
        <button
          className={`${buttonRight ? 'tw-right-0' : 'tw-left-0'} ${buttonClassName}`}
          onClick={(e) => {
            e.preventDefault();
            triggerSearch(searchKey);
          }}
        >
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className='tw-p-0.5'>
            <path d="M16.9998 15.9961L14.226 13.3711C13.6986 14.0501 13.0666 14.6408 12.3535 15.1211L15.206 17.8336C15.326 17.9737 15.4743 18.0867 15.6411 18.1653C15.808 18.2438 15.9896 18.2861 16.174 18.2892C16.3584 18.2924 16.5414 18.2564 16.7108 18.1836C16.8803 18.1108 17.0324 18.0029 17.1571 17.867C17.2817 17.7311 17.3762 17.5703 17.4341 17.3953C17.492 17.2202 17.5122 17.0348 17.4932 16.8513C17.4742 16.6679 17.4165 16.4906 17.3239 16.3311C17.2313 16.1716 17.1059 16.0336 16.956 15.9261L16.9998 15.9961Z" fill="#1A1A1A"/>
            <path d="M7.9336 0.246094C6.37607 0.246094 4.85352 0.707954 3.55848 1.57327C2.26344 2.43859 1.25409 3.66849 0.658046 5.10746C0.0620058 6.54643 -0.0939454 8.12983 0.209913 9.65743C0.513772 11.185 1.26379 12.5882 2.36513 13.6896C3.46647 14.7909 4.86966 15.5409 6.39726 15.8448C7.92486 16.1486 9.50826 15.9927 10.9472 15.3966C12.3862 14.8006 13.6161 13.7912 14.4814 12.4962C15.3467 11.2012 15.8086 9.67862 15.8086 8.12109C15.8086 6.03251 14.9789 4.02948 13.5021 2.55263C12.0252 1.07578 10.0222 0.246094 7.9336 0.246094ZM7.9336 13.6861C6.83505 13.6861 5.76115 13.3604 4.84762 12.7503C3.93409 12.1402 3.22193 11.2729 2.80113 10.2581C2.38034 9.24338 2.2698 8.12665 2.48348 7.04909C2.69716 5.97152 3.22547 4.98148 4.00165 4.20407C4.77783 3.42667 5.76704 2.8968 6.84427 2.68142C7.9215 2.46605 9.0384 2.57483 10.0538 2.99403C11.0693 3.41323 11.9376 4.12403 12.5492 5.03659C13.1608 5.94916 13.4881 7.02255 13.4898 8.12109C13.4922 8.85181 13.3501 9.57578 13.0718 10.2514C12.7935 10.9271 12.3844 11.5411 11.8681 12.0582C11.3518 12.5753 10.7385 12.9853 10.0633 13.2647C9.38806 13.544 8.66432 13.6873 7.9336 13.6861Z" fill="#1A1A1A"/>
          </svg>
        </button>
      </div>
    </div>
  );

  const getNavParent = (element) => {
    while (element && element.tagName !== 'NAV') {
      element = element.parentElement;
    }
    return element;
  };

  return (
    <div className={`header-actions ${parentClassName}`}>
      {isIcon && (
        <div class="action-item"  onClick={() => setIsSearchVisible(true)}>
          <div className='action-item-icon'>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.6152 14.4625L13.0792 12.0625C12.597 12.6833 12.0191 13.2234 11.3672 13.6625L13.9752 16.1425C14.0849 16.2706 14.2204 16.3739 14.373 16.4457C14.5256 16.5176 14.6916 16.5562 14.8602 16.5591C15.0288 16.562 15.1961 16.529 15.351 16.4625C15.506 16.396 15.645 16.2973 15.759 16.1731C15.873 16.0488 15.9593 15.9018 16.0123 15.7417C16.0653 15.5817 16.0837 15.4122 16.0663 15.2444C16.0489 15.0767 15.9962 14.9146 15.9115 14.7688C15.8269 14.623 15.7122 14.4967 15.5752 14.3985L15.6152 14.4625Z" fill="#1A1A1A"/>
              <path d="M7.33282 0.0625C5.90879 0.0625 4.51674 0.484773 3.33271 1.27592C2.14868 2.06707 1.22583 3.19155 0.680883 4.50718C0.135932 5.82281 -0.00665186 7.27049 0.271162 8.66715C0.548975 10.0638 1.23471 11.3467 2.24165 12.3537C3.24858 13.3606 4.5315 14.0463 5.92816 14.3242C7.32483 14.602 8.77251 14.4594 10.0881 13.9144C11.4038 13.3695 12.5283 12.4466 13.3194 11.2626C14.1105 10.0786 14.5328 8.68653 14.5328 7.2625C14.5328 5.35294 13.7742 3.52159 12.424 2.17133C11.0737 0.821069 9.24238 0.0625 7.33282 0.0625ZM7.33282 12.3505C6.32843 12.3505 5.34658 12.0528 4.51135 11.4949C3.67612 10.9371 3.02501 10.1442 2.64028 9.21638C2.25555 8.28859 2.15448 7.26758 2.34985 6.28238C2.54521 5.29718 3.02824 4.39199 3.73789 3.68122C4.44755 2.97046 5.35197 2.486 6.33686 2.28909C7.32176 2.09217 8.34292 2.19163 9.27131 2.5749C10.1997 2.95817 10.9936 3.60804 11.5528 4.44239C12.112 5.27673 12.4112 6.25811 12.4128 7.2625C12.4149 7.93058 12.285 8.5925 12.0306 9.21024C11.7761 9.82797 11.4021 10.3893 10.9301 10.8621C10.4581 11.3349 9.89728 11.7098 9.27995 11.9652C8.66261 12.2206 8.0009 12.3516 7.33282 12.3505Z" fill="#1A1A1A"/>
            </svg>
          </div>
        </div>
      )}

      {isIcon && isSearchVisible && 
        ReactDOM.createPortal(
          <div className="header-actions search-overlay">
            {searchInput}
          </div>,
          getNavParent(document.querySelector('.header-actions')) || document.body
        )
      }

      {!isIcon && searchInput}
    </div>
  );
};

export default Search;
