import React from "react";
import "./stylecustomTextForAgeRestriction.css";
import h2p from "html2plaintext";


export default function CustomTextForAgeRestriction({text}) {
  return (
    <div className="scrolling-text">
      <marquee>
        <span className="text-wrapper">{h2p(text)}</span>
      </marquee>
    </div>
  );
}
