import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import Footer from "../wrappers/footer/Footer";
import FooterSkeleton from "./FooterSkeleton";
import Header from "../components/templates/header/header";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
  templateStyles
}) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div>
      <Header templateStyles={templateStyles}/>

      {templateStyles?.['nav_template'] == 'default' && (
        <div className="extra-row"></div>
      )}
      {children}
      {isLoading ? <FooterSkeleton /> : <Footer />}
    </div>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default LayoutOne;
