import React from 'react';
import CategoryList from '../header/components/categoryList';
import Brands from './components/brands';

const NavCategoriesTemplate3 = ({ position, activeCategory, brandsData }) => {
  const MIN_WIDTH = 800
  const RIGHT_OFFSET = 20
  const subCategories = activeCategory?.children ?? [];
  console.log('activeCategory', activeCategory)
  if (!activeCategory || subCategories.length === 0) return null;

  return (
    <div
      className="mega-menu"
      style={position && {
        top: position.top,
        left: window.innerWidth - position.left < MIN_WIDTH ? window.innerWidth - MIN_WIDTH : position.left,
        right: RIGHT_OFFSET,
        bottom: 'auto',
        width: MIN_WIDTH - RIGHT_OFFSET
      }}
    >
      <div className="dropdown-content">
        <div className="brand-info">
          <h2>
            Brands
          </h2>
          <Brands brandsData={brandsData} />
        </div>
        
        {subCategories && subCategories.length > 0 && (
          <div className='tw-flex tw-flex-col'>
            <b className='tw-ml-6 tw-capitalize tw-mb-3'>{activeCategory.name.capitalize()}</b>
            <CategoryList activeCategory={activeCategory} categories={subCategories} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavCategoriesTemplate3;
