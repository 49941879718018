import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import { connect } from "react-redux";
import HomeSearch from "../../components/header/HomeSearch";
import CustomTextForAgeRestriction from "../../components/age-restriction-section/CustomTextForAgeRestriction";
import { Skeleton } from "antd";
import { Link } from "react-router-dom";

const HeaderOne = ({ settingData }) => {
  const [navbarStyle, setNavbarStyle] = useState({});

  const handleScroll = () => {
    const tickerHeight = document.querySelector('.ticker')?.offsetHeight || 0;
    if (window.scrollY > tickerHeight) {
      setNavbarStyle({
        position: 'fixed',
        top: 0,
        // width: '100%',
        zIndex: 100,
        transition: 'top 0.3s ease-in-out',
      });
    } else {
      setNavbarStyle({});
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="header-tmpl tw-top-0 tw-fixed tw-w-full tw-z-10">
      <div className="row navbar" style={navbarStyle}>
        <div className="headerLogo">
          <Link to={process.env.PUBLIC_URL + "/home"}>
            {isLoading && (
              <Skeleton.Button
                style={{ width: "120px", height: "60px" }}
                active
                inline
              />
            )}
            {!isLoading && (
              <img
                className="main-logo"
                // alt="your site logo"
                src={settingData.logo}
                onLoad={() => setLoading(false)}
              />
            )}
          </Link>
        </div>
        <div
          className="navLinks hide-for-tablet-and-mobile"
          style={{ marginTop: "6px" }}
        >
          <NavMenu settingData={settingData} />
        </div>
        <div style={{ display: "flex" }} className="hide-for-tablet-and-mobile">
          <div>
            
              <HomeSearch />
          </div>
          <div>
            {isLoading ? (
              <Skeleton.Button
                active
                size="small"
                shape="round"
                style={{
                  marginTop: "35%",
                  width: "70px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <IconGroup />
            )}
          </div>
        </div>
        <Logo imageUrl={settingData.logo} />
      </div>
    </div>
  );
};

HeaderOne.propTypes = {
  settingData: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
  };
};
export default connect(mapStateToProps)(HeaderOne);
