import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown, Menu } from "antd";
import "antd/dist/antd.css"; // Import Ant Design styles
import { deleteFromCart } from "../../redux/actions/cartActions";
import {
  fetchProductRequest,
  searchProduct,
} from "../../redux/actions/productActions";
import { searchFilter } from "../../pages/constant/Constants";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { Spin } from "antd";
import { store } from "../..";
import { useMainUIContext } from "../../UIContext";
import { getSearchKey, search_key_presist } from "../../pages/constant/utils";

const HomeSearch = ({ settingData, currency, isMobile, productSearch }) => {
  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);


  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      searchKey: mainUIContext.searchKey,
      setSearchKey: mainUIContext.setSearchKey,
      enterSearchIcon: mainUIContext.enterSearchIcon, 
      setEnterSearchIcon: mainUIContext.setEnterSearchIcon,
      setSelectedMainCategory: mainUIContext.setSelectedMainCategory,
      setViewAllProducts:mainUIContext.setViewAllProducts,
      setFilter:mainUIContext.setFilter,
      setSearchTag:mainUIContext.setSearchTag,
      setSelectedBrands:mainUIContext.setSelectedBrands,
    };
  }, [mainUIContext]);
  const { searchKey, setSearchKey, setSelectedMainCategory, setEnterSearchIcon, setSearchTag,setFilter,setSelectedBrands } = mainUIProps;

  const handleFilter = useCallback(debounce((e) => {
    const searchKey = e.target.value;
    getProductsList(searchKey);
  }, 1000), []);
  const productSearchEnter = localStorage.getItem("shop_search_filters")

  const handleKeyPress = () => {
    setIsFocused(false)
    let enterSearch = searchKey.replace(/%20/g, ' ');
      localStorage.setItem("shop_search_filters", "");
      history.push(`/shop?search=${enterSearch}`);
      setEnterSearchIcon(true)
      setFilter("")
      setSearchKey(enterSearch)
      setSelectedMainCategory && setSelectedMainCategory("")
      localStorage.setItem("parentCategory", "");
      localStorage.setItem("brandsPreserve", "");
      setSelectedBrands("")
  };

  const handleSearchAllClick = () => {
      handleKeyPress()
      setEnterSearchIcon(true)
      setSearchTag(prev => ({
        ...prev,
        category_ids: null
    }));
  };

  const getProductsList = async (search_key) => {
    try {
      let params = {
        ...searchFilter,
        brand_ids:"",
        search_key,
        sort_by: "created_at:desc",
        home_search: "menu",
        default_variant: "",
        page_size:5,
      };

      const response = await fetchProductRequest(params);
      setSearchResults(response.data.entities);
    } catch (err) { 
    }
  };

  useEffect(() => {
    if(!["/shop"].includes(history?.location?.pathname)) {
      // store.dispatch(searchProduct(""));
      setSearchKey("")
      document.getElementById("input-header-search").value = ""
    }
  }, [history?.location?.pathname])

  useEffect(()=>{
    if(!isMobile && isFocused){
      setIsFocused(false);
    }
  },[isMobile])


  const onProductClick = (product) => {
    history.push(`/product/${product.slug}`)
  };

  const dropdownMenu = (
    <Menu className="searchbar-menu">
      {isFocused &&
        searchResults.map((product, index) => (
          <Menu.Item key={product.id} onClick={() => onProductClick(product)}>
            <div
              style={{
                borderBottom:
                  index !== searchResults.length - 1
                    ? "1px solid #f0f0f0"
                    : "none",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + product.images[0]}
                style={{ width: "50px",height: "50px", marginRight: "50px" }}
                alt={product.ecom_name || product.name}
              />
              <div style={{ flex: 1, marginRight: "10px" }}>
                <p
                  style={{
                    margin: 0,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    lineHeight: "1.2em", // Adjust as needed
                    maxHeight: "2.4em", // This should be lineHeight multiplied by the number of lines
                  }}
                >
                  {product.ecom_name || product.name}
                </p>
              </div>
              {settingData.price_display_for_unsecure_user && (
                <span style={{ margin: 0, fontSize: "16px", color: "var(--style-primary-color)" }}>
                  {currency.currency_symbol}
                  {product.selected_unit_price.price}
                </span>
              )}
            </div>
          </Menu.Item>
        ))}
         <Menu.Item key="search-all" onClick={handleSearchAllClick} className="search-last-menu ">
          <div style={{ display: "flex", alignItems: "center", justifyContent:"center" }}>
            <span>View All Results</span>
          </div>
        </Menu.Item>
    </Menu>
  );

  const showSearchValue = () => {
      return searchKey || ""
  }

  return (
    <div className={`header-margin`}>
      <div className="search-position">
        {" "}
        {/* Wrapper for search bar and dropdown with inline style */}
        <div className="home-search-products-bar" >
        <Spin spinning={loading}>
          <input
          id="input-header-search"
            className={`header-searchbar`}
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              setSearchKey(value);
              const newUrl = new URL(window.location.href);
              if (value !== "") {
                newUrl.searchParams.set('search', value);
              } else {
                newUrl.searchParams.delete('search');
              }
              handleFilter(e);
              window.history.replaceState({}, '', newUrl.toString());
              setIsFocused(value !== "");
            }}
            value={searchKey || getSearchKey()}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleKeyPress()
              }
            }}
            // onFocus={() => setIsFocused(true)}
            onBlur={() => setTimeout(() => setIsFocused(false), 300)}
            placeholder="Search Products"
          />
          </Spin>
          <div className="search-icon" onClick={handleSearchAllClick}>
            <img
              src={`${process.env.PUBLIC_URL}/media/icons/Searchwhite.svg`}
              alt="Search"
              style={{
                position: 'absolute',
                top: '50%', // Center vertically
                left: '50%', // Center horizontally
                transform: 'translate(-50%, -50%)', // Adjust for exact centering
                height: '16px'
              }}
            />
          </div>
          <div>
          

          </div>
        </div>
        {searchResults.length > 0 && !isMobile? (
          <Dropdown
            overlay={dropdownMenu}
            visible={isFocused}
            getPopupContainer={() =>
              document.querySelector(".home-search-products-bar")
            }
            overlayClassName="home-search-products-list-container"
          >
            <div className="search-dropdown"></div>
          </Dropdown>
        ):searchResults.length > 0 && (
          <Dropdown
                      overlay={dropdownMenu}
                      visible={isFocused}
                      // placement="bottomLeft"
                      overlayClassName="home-search-products-list-container"           
                    >
                      <div className="search-dropdown"></div>
                    </Dropdown>
            )}
      </div>
    </div>
  );
};

HomeSearch.propTypes = {
  settingData: PropTypes.object,
  currency: PropTypes.object,
  isMobile:PropTypes.bool,
  productSearch: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
  currency:state.settingData?.["admin-panel"]?.basic||{},
  productSearch: state.productData.search_key,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (cartItems, item, channel_id, addToast) => {
      dispatch(deleteFromCart(cartItems, item, channel_id, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearch);
